<template>
  <router-view />
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
export default {
  name: 'project-location-home',
  components: {},
  computed: {
    ...mapState(['tenant', 'project', 'camera']),
  },

  /**
   * LIFECYCLE HOOKS
   */
  beforeRouteEnter(to, from, next) {
    getPageData(to, next);
  },
  beforeRouteUpdate(to, from, next) {
    getPageData(to, next);
  },
};

async function getPageData(to, next) {
  await store.dispatch('tenant/fetchTenant', to.params.id);
  await store.dispatch('project/fetchProjectById', to.params.project_id);
  if (to.params.camera_id) {
    await store.dispatch('camera/fetchCameraById', to.params.camera_id);
  }

  next();
}
</script>

<style lang="sass" scoped>
.viewport
  background: #242424
</style>
